<template>
  <component
    :is="htmlType"
    class="print:hidden"
    :class="[inSidebar && 'project-items-in-sidebar']"
    :space-before="!inSidebar"
    :space-after="!inSidebar"
  >
    <div class="border border-solid p-6 rounded-lg border-gray-200">
      <Headline v-if="headline" :level="inSidebar ? 4 : 3">
        {{ headline }}
      </Headline>
      <fieldset
        class="project-items-content grid border-0 sm:grid-cols-1 m-0 p-0 gap-4 mb-4 mt-6"
      >
        <ProjectItem
          v-for="item in projectItems"
          :key="item.id"
          :in-sidebar="inSidebar"
          :element="item"
          @select="onSelectItem"
        />
      </fieldset>
      <div v-if="alerts.length">
        <Alert v-for="alert in alerts" :key="alert.message" :type="alert.type">
          <template #icon>
            <SvgIcon icon="x-circle" />
          </template>
          {{ alert.message }}
        </Alert>
      </div>
      <Button class="w-full" :loading="addingToCart" @click="addToCart">
        {{ buttonLabel || "Add items to cart" }}
      </Button>
    </div>
  </component>
</template>

<script setup lang="ts">
  import ProjectItem from "./project_item.vue"
  import { useCartStore } from "~/pinia/cart"

  const Container = defineAsyncComponent(
    () => import("~/components/Container/index.vue"),
  )

  const { $csGtm } = useNuxtApp()

  const props = withDefaults(
    defineProps<{
      element: AlchemyElement
      inSidebar: boolean
    }>(),
    {
      inSidebar: false,
    },
  )

  const htmlType = computed(() => {
    return props.inSidebar ? "div" : Container
  })

  const { getValue } = useAlchemy()
  const { alerts, handleError } = useErrorHandling()

  const itemsForCart: Ref<ItemsForCart> = ref({})
  const addingToCart = ref(false)

  const headline = getValue(props.element, "headline") || ""
  const buttonLabel = getValue(props.element, "button_label") || ""

  const projectItems = props.element.nestedElements

  const createIngredientObject = (
    ingredients: AlchemyIngredient[],
  ): { variant: string; quantity: string } => {
    return ingredients.reduce(
      (acc, { role, value }) => ({
        ...acc,
        [role]: value,
      }),
      {} as any,
    )
  }

  itemsForCart.value = Object.fromEntries(
    projectItems
      .map(({ ingredients }) => ingredients)
      .map(createIngredientObject)
      .map(({ variant, quantity }) => [variant, parseInt(quantity)]),
  )

  onMounted(() => {
    $csGtm.trackEvent({
      event: `hasProjectItems`,
    })
  })

  const onSelectItem = (payload: {
    checked: boolean
    variantId: string
    quantity: string
  }) => {
    if (payload.checked) {
      Object.assign(itemsForCart.value, {
        [payload.variantId]: payload.quantity,
      })
    } else {
      Object.assign(itemsForCart.value, {
        [payload.variantId]: 0,
      })
    }
  }

  const addToCart = async () => {
    addingToCart.value = true
    try {
      await useCartStore().addToCart(itemsForCart.value)
    } catch (error) {
      handleError(error)
    } finally {
      addingToCart.value = false
    }
  }
</script>

<style lang="postcss">
  .project-items-content {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
</style>
